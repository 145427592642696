import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./CandidateSearch.scss";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as toast from "@/wrapper/toast";
import * as ApiHelper from "@/services/apiHelper";
import * as storageConstants from "@/constants/storageConstants";
import MultiRangeSlider from "../Common/MultiRangeSlider/TotalExpSlider";
import * as commonServices from "@/services/common";
import { CURRENCY } from "@/constants/storageConstants";
import ModalLoginRequired from "../Modals/ModalLoginRequired";
import Chart from "../Common/Chart";
import emptyResult from "@/Assets/svgs/search_no_result.svg";
import DateRangePicker from "../Common/DateRangePicker";
import { isCandidate, isLoggedIn } from "@/utils/authUtil";
import PayWall from "../Common/PayWall";
import { consoleLogE } from "@/utils/console";
import { INVALID_KEYWORD_ERROR_MESSAGE } from "@/constants/stringConstants";
import CustomPagination from "../Pagination/Pagination";
import { getSkillsString, textCapitalise } from "@/utils/stringUtils";
import {
  getCandidateExperienceText,
  getBarColor,
  getPercentage,
} from "@/services/common";
import mapPin from "@/Assets/svgs/map-pin.svg";
import { removeDuplicates } from "@/utils/arrayUtils";
import ModalLocation from "../Modals/ModalLocation";
import { changeDateFormat } from "@/utils/dateUtil";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { addNavShadow } from "@/utils/styleUtils";
import { validateKeyword } from "@/utils/validation";
import Text from "../_widgets/Text/Text";
import { RxCross2 } from "react-icons/rx";
import {
  capitalizeText,
  highlightText,
  isNullOrEmpty,
} from "../../utils/stringUtils";
import Button from "../_widgets/Button/Button";
import IconResource from "../_widgets/IconResource/IconResource";
import ModalShowSimilarCandidates from "../Modals/ModalShowSimilarCandidates";
import {
  SIMILAR_SEARCH_CRITERIA,
  CANDIDATE_SEARCH_FILTERS,
  ORDER_BY,
  ORDER_BY_TEXT,
  SEARCH_FILTERS,
} from "../../constants/searchConstants";
import ModalSaveSearchCriteria from "../Modals/ModalSaveSearchCriteria";
import { getIdWithPrefix } from "../../utils/userProfileID";
import FilterBar from "../_widgets/FIlterBar/FilterBar";
import { addCandidateToSaveLater, getLocalData, storeLocalData } from "../../services/common";
import { LOCATIONS, SKILLS } from "../../constants/storageConstants";
import { convertArrayElementsToInteger, transformArrayFromObject } from "../../utils/arrayUtils";
import {
  addMultipleSearchParamsInHistory,
  addSearchParamsInHistory,
  removeSearchParamsInHistory,
  removeMultipleSearchParamsInHistory,
  removeAllParams,
  redirectBack,
  removeSearchParamsInHistoryWithoutValue,
} from "../../utils/redirectUtil";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getChipsNameFromMasterData, getLocationChipsName, getSkillChipsName, returnNewPayloadArray } from "../../utils/searchUtils";
import CustomTooltip from "../../utils/CustomTooltip";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function CandidateSearch({
  jobSearch,
  locationSearch,
  locationSearchName,
  jobSearchName,
  searchType,
  keywordSearch,
  setShowStickySearchBar,
  isStickySearch,
  setIsStickySearch,
  setSearchFilterState,
  keywordSeachFilters,
  setKeywordSeachFilters,
}) {

  const getURLSearchParams = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.getAll(name);
  };
  const [filting, setFilting] = useState("hidden");
  let [loading, setLoading] = useState(false);
  let [chart1loading, setChart1Loading] = useState(false);
  let [chart2loading, setChart2Loading] = useState(false);
  let [color] = useState("#000000");
  const auth = getLocalData(storageConstants.AUTH);

  const datePickerStartRef = useRef();
  const datePickerEndRef = useRef();
  var searchTypeParam = getURLSearchParams(SEARCH_FILTERS?.SEARCH_TYPE);
  var keywordParam = getURLSearchParams(SEARCH_FILTERS?.KEYWORD);
  const [keyword, setKeyword] = useState(keywordParam && keywordParam[0] !== undefined ? keywordParam[0] : keywordSearch ? keywordSearch : "");
  const [searchBy, setSearchBy] = useState(searchTypeParam && searchTypeParam[0] !== undefined ? searchTypeParam[0] : searchType ? searchType : "smart");

  const masterData = getLocalData(storageConstants.DATA);

  const navigate = useNavigate();

  const initialDisplayLocations = {
    count: getLocalData(LOCATIONS)?.length - 10,
    arr: getLocalData(LOCATIONS),
  };

  const [displayLocations, setDisplayLocations] = useState(
    initialDisplayLocations
  );

  const [job_search, setJob_search] = useState(jobSearch ? jobSearch : []);
  const [location, setLocation] = useState(
    locationSearch ? locationSearch : []
  );
  const [isShowPercentage, setIsShowPercentage] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalLocation, setShowModalLocation] = useState(false);
  const [currentCandidateId, setCurrentCandidateId] = useState();

  const [jobLocations, setJobLocations] = useState(
    locationSearchName ? locationSearchName : []
  );
  const [noResultErr, setNoResultErr] = useState(false);

  const [orderByUser, setOrderByUser] = useState(false);

  const [preferredSkillName, setPreferredSkillName] = useState(
    jobSearchName ? jobSearchName : []
  );
  const [chartData, setChartData] = useState({});
  const [skillGraphDataIndex, setSkillGraphDataIndex] = useState();
  const [skillGraphDatasetIndex, setSkillGraphDatasetIndex] = useState();
  const [graphSkillsId, setGraphSkillsId] = useState([]);
  const [graphJobs, setGraphJobs] = useState([]);
  const [jsonPayload, setJsonPayload] = useState();

  const [graphSkillsType] = useState("bar");
  const [graphLocationsType] = useState("bar");
  const [graphSkillsTitle] = useState("");
  const [graphLocationsTitle] = useState("");
  const [chartLocationData] = useState({});
  const [locationGraphDataIndex, setLocationGraphDataIndex] = useState();
  const [locationGraphDatasetIndex, setLocationGraphDatasetIndex] = useState();
  const [graphLocationsId] = useState([]);
  const ranges = ["0-5", "5-10", "10+"];
  const skillChartRef = useRef();
  const locationChartRef = useRef();
  const skillListViewChartRef = useRef();
  const locationListViewChartRef = useRef();

  const [activePage, setActivePage] = useState(1);

  const [userNames, setUserNames] = useState([]);
  const [userTTId, setUserTTId] = useState(null);

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
    FindWork(activePage);
  };

  const [resetSlider, setResetSlider] = useState(true);

  useEffect(() => {
    window.addEventListener('popstate', handleBrowserNavigation);
    setParamsFromURL();

    return () => {
      window.removeEventListener('popstate', handleBrowserNavigation);
    };
  }, []);

  const handleBrowserNavigation = (event) => {
    // Back or forward button clicked
    if (event.state) {
      setParamsFromURL();
    }
  };

  

  const defaultPayload = {
    skills: [],
    locations: [],
    min_salary: [],
    max_salary: [],
    salary_id: [],
    job_types: [],
    preferences: [],
    min_exp: [0],
    max_exp: [20],
    layoff: ["0"],
    herCareerReboot: ["0"],
    differentlyAbled: ["0"],
    armedForces: ["0"],
    eeo: ["0"],
    start_date: [null],
    end_date: [null],
    education: [],
    employment: [],
    orderBy: [ORDER_BY.NEWEST],
    orderByText: [ORDER_BY_TEXT.NEWEST],
    keywordReset: [false],
  };
  
  var skillParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.SKILLS);
  var locationParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.LOCATIONS);
  var minSalaryParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.MIN_SALARY);
  var maxSalaryParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.MAX_SALARY);
  var salaryIdParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.SALARY_ID);
  var jobTypeParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.JOB_TYPES);
  var preferenceParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.PREFERENCES);
  var minExperienceParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.MIN_EXP);
  var maxExperienceParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.MAX_EXP);
  var layoffParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.LAYOFF);
  var rebootParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.REBOOT);
  var diffAbledParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.DIFF_ABLED);
  var forcesParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.FORCES);
  var eeoParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.EEO);
  var startDateParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.START_DATE);
  var endDateParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.END_DATE);
  var educationParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.EDUCATION);
  var employmentParam = getURLSearchParams(CANDIDATE_SEARCH_FILTERS?.EMPLOYMENT);


  const initialPayload = {
    skills: convertArrayElementsToInteger(skillParam),
    locations: convertArrayElementsToInteger(locationParam),
    min_salary: convertArrayElementsToInteger(minSalaryParam),
    max_salary: convertArrayElementsToInteger(maxSalaryParam),
    salary_id: convertArrayElementsToInteger(salaryIdParam),
    job_types: convertArrayElementsToInteger(jobTypeParam),
    preferences: convertArrayElementsToInteger(preferenceParam),
    min_exp: minExperienceParam.length > 0 ? convertArrayElementsToInteger(minExperienceParam) : [0],
    max_exp: maxExperienceParam.length > 0 ? convertArrayElementsToInteger(maxExperienceParam) : [20],
    layoff: layoffParam.length > 0 ? layoffParam : ["0"],
    herCareerReboot: rebootParam.length > 0 ? rebootParam : ["0"],
    differentlyAbled: diffAbledParam.length > 0 ? diffAbledParam : ["0"],
    armedForces: forcesParam.length > 0 ? forcesParam : ["0"],
    eeo: eeoParam.length > 0 ? eeoParam : ["0"],
    start_date: startDateParam.length > 0 ? new Date(startDateParam[0]) : [null],
    end_date: endDateParam.length > 0 ? new Date(endDateParam[0]) : [null],
    education: educationParam,
    employment: employmentParam,
    orderBy: [skillParam.length > 0 || locationParam.length > 0 ? ORDER_BY.RELEVANT : ORDER_BY.NEWEST],
    orderByText: [skillParam.length > 0 || locationParam.length > 0 ? ORDER_BY_TEXT.RELEVANT : ORDER_BY_TEXT.NEWEST],
    keywordReset: [false],
  };

  const [searchPayload, setSearchPayload] = useState(initialPayload);

  const setParamsFromURL = () => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    const similarUserID = searchParams.get("u");
    const id = !isNullOrEmpty(similarUserID) ? parseInt(similarUserID) : null;
    setUserTTId(id);

    const isSimilarSkills = searchParams.get("isSimilarSkills");
    const isSimilarLocation = searchParams.get("isSimilarLocation");
    const emp = searchParams.get("employment");
    const edu = searchParams.get("education");
    const tempSelectedCriterias = [];
    !isNullOrEmpty(isSimilarSkills) && isSimilarSkills[0] === "1" && tempSelectedCriterias.push(SIMILAR_SEARCH_CRITERIA.SKILLS);
    !isNullOrEmpty(isSimilarLocation) && isSimilarLocation[0] === "1" && tempSelectedCriterias.push(SIMILAR_SEARCH_CRITERIA.LOCATION);
    !isNullOrEmpty(emp) && tempSelectedCriterias.push(SIMILAR_SEARCH_CRITERIA.EMPLOYMENT);
    !isNullOrEmpty(edu) && tempSelectedCriterias.push(SIMILAR_SEARCH_CRITERIA.EDUCATION);
    setSelectedCriterias(tempSelectedCriterias);

    if (!isNullOrEmpty(edu) || !isNullOrEmpty(emp)) {
      const val = !isNullOrEmpty(edu) ? edu.split("Education - ") : !isNullOrEmpty(emp) ? emp.split("Employment - ") : '';
      const name = !isNullOrEmpty(val[1]) && val[1]?.split("(TT")[0]?.trim();
      setUserNames([{ name, id }]);
    }

    const keyArr = [];
    const valArr = [];
    for (const [key, value] of searchParams.entries()) {
      keyArr.push(key);
      valArr.push(value);
    }

    setDataInPayload(keyArr, valArr);
  };

  useEffect(() => {
    setResetSlider(!resetSlider);
  }, [searchPayload.min_exp, searchPayload.max_exp]);



  const singleValueArrTypes = [
    CANDIDATE_SEARCH_FILTERS.MIN_EXP,
    CANDIDATE_SEARCH_FILTERS.MAX_EXP,
    CANDIDATE_SEARCH_FILTERS.START_DATE,
    CANDIDATE_SEARCH_FILTERS.END_DATE,
    CANDIDATE_SEARCH_FILTERS.EDUCATION,
    CANDIDATE_SEARCH_FILTERS.EMPLOYMENT,
  ];

  const multiValueArrTypes = [
    CANDIDATE_SEARCH_FILTERS.SKILLS,
    CANDIDATE_SEARCH_FILTERS.LOCATIONS,
    CANDIDATE_SEARCH_FILTERS.SALARY_ID,
    CANDIDATE_SEARCH_FILTERS.MIN_SALARY,
    CANDIDATE_SEARCH_FILTERS.MAX_SALARY,
    CANDIDATE_SEARCH_FILTERS.JOB_TYPES,
    CANDIDATE_SEARCH_FILTERS.PREFERENCES,
  ];

  const specialTypes = [
    CANDIDATE_SEARCH_FILTERS.LAYOFF,
    CANDIDATE_SEARCH_FILTERS.REBOOT,
    CANDIDATE_SEARCH_FILTERS.DIFF_ABLED,
    CANDIDATE_SEARCH_FILTERS.FORCES,
    CANDIDATE_SEARCH_FILTERS.EEO,
  ];

  const validKeys = [
    CANDIDATE_SEARCH_FILTERS.SKILLS,
    CANDIDATE_SEARCH_FILTERS.LOCATIONS,
    CANDIDATE_SEARCH_FILTERS.SALARY_ID,
    CANDIDATE_SEARCH_FILTERS.MIN_SALARY,
    CANDIDATE_SEARCH_FILTERS.MAX_SALARY,
    CANDIDATE_SEARCH_FILTERS.JOB_TYPES,
    CANDIDATE_SEARCH_FILTERS.PREFERENCES,
    CANDIDATE_SEARCH_FILTERS.MIN_EXP,
    CANDIDATE_SEARCH_FILTERS.MAX_EXP,
    CANDIDATE_SEARCH_FILTERS.LAYOFF,
    CANDIDATE_SEARCH_FILTERS.REBOOT,
    CANDIDATE_SEARCH_FILTERS.DIFF_ABLED,
    CANDIDATE_SEARCH_FILTERS.FORCES,
    CANDIDATE_SEARCH_FILTERS.EEO,
    CANDIDATE_SEARCH_FILTERS.START_DATE,
    CANDIDATE_SEARCH_FILTERS.END_DATE,
    CANDIDATE_SEARCH_FILTERS.EDUCATION,
    CANDIDATE_SEARCH_FILTERS.EMPLOYMENT,
  ];

  const setDataInPayload = (keyArr, valueArr) => {
    let tempPayload = {
      skills: [],
      locations: [],
      min_salary: [],
      max_salary: [],
      salary_id: [],
      job_types: [],
      preferences: [],
      min_exp: [0],
      max_exp: [20],
      layoff: ["0"],
      herCareerReboot: ["0"],
      differentlyAbled: ["0"],
      armedForces: ["0"],
      eeo: ["0"],
      start_date: [null],
      end_date: [null],
      education: [],
      employment: [],
      orderBy: [ORDER_BY.NEWEST],
      orderByText: [ORDER_BY_TEXT.NEWEST],
      keywordReset: [false],
    };

    if (
      Array.isArray(keyArr) &&
      Array.isArray(valueArr) &&
      keyArr.length > 0 &&
      valueArr.length === keyArr.length
    ) {
      keyArr.forEach((key, index) => {
        if (validKeys.includes(key)) {
          if (!tempPayload[key]) {
            tempPayload[key] = [];
          }

          if (singleValueArrTypes.includes(key) || specialTypes.includes(key)) {
            if (key === "start_date" || key === "end_date") {
              tempPayload[key][0] = new Date(valueArr[index]);
            } else if (key === "min_exp" || key === "max_exp") {
              tempPayload[key][0] = parseInt(valueArr[index]);
            } else {
              tempPayload[key][0] = valueArr[index];
            }
          } else if (multiValueArrTypes.includes(key)) {
            if (!tempPayload[key].includes(parseInt(valueArr[index]))) {
              tempPayload[key].push(parseInt(valueArr[index]));
            }
          }
        }
      });
    }

    if (!orderByUser && (tempPayload['skills'].length > 0 || tempPayload['locations'].length > 0)) {
      tempPayload['orderBy'] = [ORDER_BY.RELEVANT];
      tempPayload['orderByText'] = [ORDER_BY_TEXT.RELEVANT];
    }
    else if (!orderByUser && (tempPayload['skills'].length < 1 || tempPayload['locations'].length < 1)) {
      tempPayload['orderBy'] = [ORDER_BY.NEWEST];
      tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
    }

    setSearchPayload(tempPayload);
  };

  const handleDisplayLocationChange = (text) => {
    const locArr = getLocalData(LOCATIONS);

    if (locArr) {
      if (text.length > 1) {
        const arr =
          locArr.length > 0
            ? locArr.filter((l) =>
              l.name.toLowerCase().includes(text.toLowerCase())
            )
            : [];

        setDisplayLocations({ count: arr.length, arr });
      } else {
        setDisplayLocations({ count: locArr.length, arr: locArr });
      }
    }
  };

  const handleDateFormatChange = (fromDate, toDate) => {
    return `${changeDateFormat(fromDate)} - ${changeDateFormat(toDate)}`;
  };
  
  
  const isDataInPayload = () => {
    let payload = {
      skills: [],
      locations: [],
      min_salary: [],
      max_salary: [],
      salary_id: [],
      job_types: [],
      preferences: [],
      min_exp: [0],
      max_exp: [20],
      layoff: ["0"],
      herCareerReboot: ["0"],
      differentlyAbled: ["0"],
      armedForces: ["0"],
      eeo: ["0"],
      start_date: [null],
      end_date: [null],
      education: [],
      employment: [],
      orderBy: [ORDER_BY.NEWEST],
      orderByText: [ORDER_BY_TEXT.NEWEST],
      keywordReset: [false],
    }
    
    return JSON.stringify(payload) !== JSON.stringify(searchPayload);
  };

  const handleTotalExpChange = (minYears, maxYears) => {
    let val;
    if (
      (minYears === 0 && maxYears !== 20) ||
      (minYears !== 0 && maxYears === 20) ||
      (minYears !== 0 && maxYears !== 20)
    ) {
      val = minYears + " Years - " + maxYears + " Years";
    } else {
      val = null;
    }
    return val;
  };

  const getChipsName = (data) => {
    const key = Object.keys(data);
    const value = Object.values(data);

    if (key[0] === "locations" && searchPayload.locations.includes(value[0])) {
      return getLocationChipsName(value[0]);
    } else if (key[0] === "skills" && searchPayload.skills.includes(value[0])) {
      return getSkillChipsName(value[0]);
    } else if (
      key[0] === "job_types" &&
      searchPayload.job_types.includes(value[0])
    ) {
      return getChipsNameFromMasterData("job_types", value[0]);
    } else if (
      key[0] === "preferences" &&
      searchPayload.preferences.includes(value[0])
    ) {
      return getChipsNameFromMasterData("joining_preferences", value[0]);
    } else if (
      key[0] === "min_exp" &&
      value[0] !== null &&
      searchPayload.min_exp.includes(value[0])
    ) {
      return handleTotalExpChange(
        searchPayload.min_exp[0],
        searchPayload.max_exp[0]
      );
    } else if (
      key[0] === "start_date" &&
      value[0] !== null &&
      searchPayload.start_date[0] !== null &&
      searchPayload.end_date[0] !== null
    ) {
      return handleDateFormatChange(
        searchPayload.start_date[0],
        searchPayload.end_date[0]
      );
    } else if (
      key[0] === "salary_id" &&
      searchPayload.salary_id.includes(value[0])
    ) {
      return getChipsNameFromMasterData("salary_range", value[0]);
    } else if (
      key[0] === "layoff" &&
      searchPayload.layoff.includes(value[0]) &&
      searchPayload.layoff[0] === "1"
    ) {
      return "Impacted by layoff";
    } else if (
      key[0] === "herCareerReboot" &&
      searchPayload.herCareerReboot.includes(value[0]) &&
      searchPayload.herCareerReboot[0] === "1"
    ) {
      return "Her Career Reboot";
    } else if (
      key[0] === "differentlyAbled" &&
      searchPayload.differentlyAbled.includes(value[0]) &&
      searchPayload.differentlyAbled[0] === "1"
    ) {
      return "Differently Abled";
    } else if (
      key[0] === "armedForces" &&
      searchPayload.armedForces.includes(value[0]) &&
      searchPayload.armedForces[0] === "1"
    ) {
      return "Armed Forces";
    } else if (
      key[0] === "eeo" &&
      searchPayload.eeo.includes(value[0]) &&
      searchPayload.eeo[0] === "1"
    ) {
      return "Travel Readiness";
    } else if (
      key[0] === "education" &&
      searchPayload.education.includes(value[0])
    ) {
      return value[0];
    } else if (
      key[0] === "employment" &&
      searchPayload.employment.includes(value[0])
    ) {
      return value[0];
    } else {
      return "";
    }
  };

  

  const manageDataInPayload = (type, data, action) => {
    const {
      SKILLS,
      LOCATIONS,
      MIN_SALARY,
      MAX_SALARY,
      SALARY_ID,
      JOB_TYPES,
      PREFERENCES,
      MIN_EXP,
      MAX_EXP,
      LAYOFF,
      REBOOT,
      DIFF_ABLED,
      FORCES,
      EEO,
      START_DATE,
      END_DATE,
      EDUCATION,
      EMPLOYMENT,
    } = CANDIDATE_SEARCH_FILTERS;
    let tempPayload = { ...searchPayload };

    if (action === "a") {
      if (type === SKILLS || type === LOCATIONS) {
        const newData =
          typeof data === "function" ? data(tempPayload[type]) : data;

        if (Array.isArray(newData)) {
          tempPayload[type] = newData;
        } else {
          tempPayload[type] = returnNewPayloadArray(
            tempPayload[type],
            newData,
            action
          );
          addSearchParamsInHistory(type, newData);
        }

        if (
          !orderByUser &&
          (tempPayload[SKILLS].length > 0 || tempPayload[LOCATIONS].length > 0)
        ) {
          tempPayload["orderBy"] = [ORDER_BY.RELEVANT];
          tempPayload["orderByText"] = [ORDER_BY_TEXT.RELEVANT];
        } else if (
          !orderByUser &&
          (tempPayload[SKILLS].length < 1 || tempPayload[LOCATIONS].length < 1)
        ) {
          tempPayload["orderBy"] = [ORDER_BY.NEWEST];
          tempPayload["orderByText"] = [ORDER_BY_TEXT.NEWEST];
        }
      } else if (type === PREFERENCES || type === JOB_TYPES) {
        tempPayload[type] = returnNewPayloadArray(
          tempPayload[type],
          data,
          action
        );
        addSearchParamsInHistory(type, data);
      } else if (type === SALARY_ID) {
        let newData = masterData?.salary_range?.filter((sr) => sr.id === data);

        const idArr = returnNewPayloadArray(tempPayload[type], data, action);
        tempPayload[type] = idArr;

        const min = parseInt(
          commonServices.removeCommaFromAmount(
            newData[0]?.value?.split("-")[0].trim()
          )
        );
        const max = parseInt(
          commonServices.removeCommaFromAmount(
            newData[0]?.value?.split("-")[1].trim()
          )
        );
        const minArr = returnNewPayloadArray(
          tempPayload[MIN_SALARY],
          min,
          action
        );
        const maxArr = returnNewPayloadArray(
          tempPayload[MAX_SALARY],
          max,
          action
        );
        tempPayload[MIN_SALARY] = minArr;
        tempPayload[MAX_SALARY] = maxArr;

        addMultipleSearchParamsInHistory(
          [MIN_SALARY, MAX_SALARY, SALARY_ID],
          [min, max, data]
        );
      } else if (type === START_DATE || type === END_DATE) {
        tempPayload[type] = [data];
      }
    } else if (action === "r") {
      if (type === SKILLS || type === LOCATIONS) {
        if (getURLSearchParams(type).length > 1) {
          const arr = returnNewPayloadArray(tempPayload[type], data, action);
          removeSearchParamsInHistory(type, data);
          tempPayload[type] = arr;
        } else {
          const similarKey =
            type === SKILLS ? "isSimilarSkills" : "isSimilarLocation";

          if (getURLSearchParams(similarKey).length > 0) {
            setSelectedCriterias(
              selectedCriterias.filter(
                (item) =>
                  item !==
                  (type === SKILLS
                    ? SIMILAR_SEARCH_CRITERIA.SKILLS
                    : SIMILAR_SEARCH_CRITERIA.LOCATION)
              )
            );
            const arr = returnNewPayloadArray(tempPayload[type], data, action);
            tempPayload[type] = arr;
            removeMultipleSearchParamsInHistory([similarKey, type], [1, data]);
          } else {
            const arr = returnNewPayloadArray(tempPayload[type], data, action);
            tempPayload[type] = arr;
            removeSearchParamsInHistory(type, data);
          }
        }

        if (
          !orderByUser &&
          (tempPayload[SKILLS].length > 0 || tempPayload[LOCATIONS].length > 0)
        ) {
          tempPayload["orderBy"] = [ORDER_BY.RELEVANT];
          tempPayload["orderByText"] = [ORDER_BY_TEXT.RELEVANT];
        } else if (
          !orderByUser &&
          (tempPayload[SKILLS].length < 1 || tempPayload[LOCATIONS].length < 1)
        ) {
          tempPayload["orderBy"] = [ORDER_BY.NEWEST];
          tempPayload["orderByText"] = [ORDER_BY_TEXT.NEWEST];
        }
      } else if (type === PREFERENCES || type === JOB_TYPES) {
        const arr = returnNewPayloadArray(tempPayload[type], data, action);
        removeSearchParamsInHistory(type, data);
        tempPayload[type] = arr;
      } else if (type === SALARY_ID) {
        const newData = masterData?.salary_range.filter((s) => s.id === data);

        const idArr = returnNewPayloadArray(tempPayload[type], data, action);
        tempPayload[type] = idArr;

        const min = parseInt(
          commonServices.removeCommaFromAmount(
            newData[0]?.value?.split("-")[0].trim()
          )
        );
        const max = parseInt(
          commonServices.removeCommaFromAmount(
            newData[0]?.value?.split("-")[1].trim()
          )
        );
        const minArr = returnNewPayloadArray(
          tempPayload[MIN_SALARY],
          min,
          action
        );
        const maxArr = returnNewPayloadArray(
          tempPayload[MAX_SALARY],
          max,
          action
        );
        tempPayload[MIN_SALARY] = minArr;
        tempPayload[MAX_SALARY] = maxArr;

        removeMultipleSearchParamsInHistory(
          [MIN_SALARY, MAX_SALARY, SALARY_ID],
          [min, max, data]
        );
      } else if (type === START_DATE || type === END_DATE) {
        tempPayload[START_DATE] = [null];
        tempPayload[END_DATE] = [null];
        removeSearchParamsInHistoryWithoutValue([START_DATE, END_DATE]);
      } else if (type === EDUCATION) {
        tempPayload[type] = [];
        setSelectedCriterias(
          selectedCriterias.filter(
            (item) => item !== SIMILAR_SEARCH_CRITERIA.EDUCATION
          )
        );

        if (
          getURLSearchParams(EDUCATION).length > 0 &&
          getURLSearchParams(EMPLOYMENT).length > 0 &&
          getURLSearchParams("u").length > 0
        ) {
          removeSearchParamsInHistory(type, data);
        } else if (
          getURLSearchParams(EDUCATION).length > 0 &&
          getURLSearchParams(EMPLOYMENT).length < 1 &&
          getURLSearchParams("u").length > 0
        ) {
          removeMultipleSearchParamsInHistory([type, "u"], [data, userTTId]);
          setUserTTId(null);
        }
      } else if (type === EMPLOYMENT) {
        tempPayload[type] = [];
        setSelectedCriterias(
          selectedCriterias.filter(
            (item) => item !== SIMILAR_SEARCH_CRITERIA.EMPLOYMENT
          )
        );

        if (
          getURLSearchParams(EMPLOYMENT).length > 0 &&
          getURLSearchParams(EDUCATION).length > 0 &&
          getURLSearchParams("u").length > 0
        ) {
          removeSearchParamsInHistory(type, data);
        } else if (
          getURLSearchParams(EMPLOYMENT).length > 0 &&
          getURLSearchParams(EDUCATION).length < 1 &&
          getURLSearchParams("u").length > 0
        ) {
          setUserTTId(null);
          removeMultipleSearchParamsInHistory([type, "u"], [data, userTTId]);
        }
      }
      else if (type === MIN_EXP || type === MAX_EXP) {
        tempPayload[MIN_EXP] = [0];
        tempPayload[MAX_EXP] = [20];
        setResetSlider(!resetSlider);
        removeSearchParamsInHistoryWithoutValue([MIN_EXP, MAX_EXP]);
      } else if (
        type === LAYOFF ||
        type === REBOOT ||
        type === DIFF_ABLED ||
        type === FORCES ||
        type === EEO
      ) {
        tempPayload[type] = ["0"];
        removeSearchParamsInHistory(type, "1");
      }
    } else {
      if (type === MIN_EXP || type === MAX_EXP) {
        tempPayload[type] = [data];
      } else if (
        type === LAYOFF ||
        type === REBOOT ||
        type === DIFF_ABLED ||
        type === FORCES ||
        type === EEO
      ) {
        tempPayload[type] = [data];
        data === '1' ? addSearchParamsInHistory(type, '1') : removeSearchParamsInHistory(type, '1');
      }
      else if (type === 'dates') {
        tempPayload[START_DATE] = [data[0]];
        tempPayload[END_DATE] = [data[1]];
      }
    }

    setSearchPayload(tempPayload);
  };

  const handleRemoveChips = (data) => {
    const k = Object.keys(data)[0];
    const v = Object.values(data)[0];
    const action = "r";

    return manageDataInPayload(k, v, action);
  };

  const clearFilters = (e) => {
    e.preventDefault();
    removeAllParams();
    setSearchPayload(defaultPayload);
    setResetSlider(!resetSlider);
    setKeyword("");
    
    document.getElementById("search_location_filter").value = "";
    setSelectedCriterias([]);
    setUserTTId(null);
    setDisplayLocations(initialDisplayLocations);
  };

  const [minYears, setMinYears] = useState(0);
  const [maxYears, setMaxYears] = useState(20);

  const [isDateFilter, setIsDateFilter] = useState(false);

  const setPreferredSkillData = (arr) => {
    arr && storeLocalData(SKILLS, arr);
  };

  const setIsKeywordReset = (value) => {
    setSearchPayload({ ...searchPayload, keywordReset: [value] });
  };

  const [featuredData, setFeaturedData] = useState([]);
  const [skillUsersCount, setSkillUsersCount] = useState(0);
  const [isPaywallActive, setIsPaywallActive] = useState(
    isLoggedIn() ? false : true
  );
  const [isPaywallActiveCancel, setIsPaywallActiveCancel] = useState(false);

  const checkPaywall = () => {
    if (!isLoggedIn()) {
      setIsPaywallActive(true);
      return false;
    } else {
      setIsPaywallActive(false);
      return true;
    }
  };

  const onLocationChange = (e, element) => {
    if (e.target.checked) {
      manageDataInPayload("locations", element.id, "a");
      document.getElementById("search_location_filter").value = "";
      addSearchParamsInHistory("locations", element.id);
    } else {
      manageDataInPayload("locations", element.id, "r");
    }
  };

  const isValidRequest = () => {
    return (
      searchType === "smart" ||
      (searchType === "keyword" && validateKeyword(keyword))
    );
  };

  const [showSimilarCandidatesModal, setShowSimilarCandidatesModal] = useState(null);

  const [selectedCriterias, setSelectedCriterias] = useState([]);

  const isSimilarCriteriaChecked = (type) => {
    return selectedCriterias.includes(type);
  };

  const showSimilarSearch = () => {
    if (isNullOrEmpty(showSimilarCandidatesModal)) {
      return;
    }

    if (selectedCriterias.length < 1) {
      toast.warn("Please select at least one criteria");
      return;
    }

    const nameParams = [];
    const valueParams = [];
    let tempPayload = { ...defaultPayload };

    const { skills, location_id, full_name, id } = showSimilarCandidatesModal;
    setLoading(true);

    const addDataInHistory = (criteria, value) => {
      nameParams.push(criteria);
      valueParams.push(value);
    };

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.SKILLS) && skills.length > 0) {
      skills.forEach((s) => {
        addDataInHistory("skills", s.id);
        tempPayload.skills.push(s.id);
      });

      addDataInHistory("isSimilarSkills", 1);
    }

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.LOCATION) && !isNullOrEmpty(location_id)) {
      tempPayload.locations.push(location_id);
      addDataInHistory("locations", location_id);

      addDataInHistory("isSimilarLocation", 1);
    }

    const addEducationOrEmploymentCriteria = (criteria) => {
      const data = `${capitalizeText(criteria)} - ${full_name} (${getIdWithPrefix(id)})`;
      addDataInHistory(criteria, data);
      tempPayload[criteria][0] = data;
    };

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EDUCATION)) {
      addEducationOrEmploymentCriteria("education");
    }

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EMPLOYMENT)) {
      addEducationOrEmploymentCriteria("employment");
    }

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EDUCATION) || isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EMPLOYMENT)) {
      addDataInHistory("u", id);
    }

    if (tempPayload.skills.length > 0 || tempPayload.locations.length > 0) {
      tempPayload["orderBy"] = [ORDER_BY.RELEVANT];
      tempPayload["orderByText"] = [ORDER_BY_TEXT.RELEVANT];
    }

    setSearchPayload(tempPayload);
    addMultipleSearchParamsInHistory(nameParams, valueParams);
    setUserNames([{ name: full_name, id }]);
    setShowSimilarCandidatesModal(null);
  };

  const FindWork = (activePage) => {
    if (isValidRequest()) {
      if (checkPaywall(activePage)) {

        const { skills, locations, min_salary, max_salary, preferences, job_types, layoff, herCareerReboot, differentlyAbled, armedForces, eeo, min_exp, max_exp, start_date, end_date } = searchPayload;

        const obj = {
          order_by: searchPayload.orderBy[0],
          limit: storageConstants.CANDIDATES_PER_PAGE_LIMIT,
          pageNumber: activePage,
          skills,
          locations,
          job_types,
          joining_preferences: preferences,
          work_authorization: [],
          is_telecommute: eeo[0],
          layoff: layoff[0],
          her_career_reboot: herCareerReboot[0],
          differently_abled: differentlyAbled[0],
          armed_forces: armedForces[0],
          min_salary,
          max_salary,
          min_experience: 12 * min_exp[0],
          max_experience: 12 * max_exp[0],
          updated_at_from: changeDateFormat(start_date[0], 'YYYY-MM-DD'),
          updated_at_to: changeDateFormat(end_date[0], 'YYYY-MM-DD'),
          q: keyword,
          keywordSeachFilters,
          similar_resume_user_id: userTTId,
          similar_criteria: selectedCriterias,
        };

        let newObj = { ...obj };
        newObj.userNames = userNames;

        setJsonPayload(newObj);
        setLoading(true);
        ApiHelper.searchCandidate(obj).then((response) => {
          if (response.isSuccess) {
            setShowSimilarCandidatesModal(null);
            window.scrollTo(0, 0);
            setFeaturedData(response.data.candidates);
            setSkillUsersCount(response.data.skill_users_count);
            if(response?.data?.candidates?.current_page !== undefined && response?.data?.candidates?.current_page !== null)
              setActivePage(response.data.candidates.current_page);
            if (response.data.candidates.data.length < 1) {
              setNoResultErr(true);
            }
            setIsShowPercentage(response.data.show_percentage);
            setOrderByUser(false);
          } else {
            consoleLogE("searchCandidate", response.message);
            toast.warn(response.message);
          }
          setLoading(false);
        });
      }
    } else {
      toast.warn(INVALID_KEYWORD_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    if (
      skillGraphDataIndex !== undefined &&
      skillGraphDatasetIndex !== undefined &&
      graphSkillsId !== undefined
    ) {
      var clickedSkillId = graphSkillsId[skillGraphDataIndex];
      var clickedRange = ranges[skillGraphDatasetIndex];
      var clickedRangeArray = clickedRange.split("-");
      if (clickedSkillId !== undefined) {
        setJob_search([parseInt(clickedSkillId)]);
      }
      if (clickedRangeArray[1] !== undefined) {
        setMinYears(clickedRangeArray[0]);
        setMaxYears(clickedRangeArray[1]);
      } else {
        setMinYears(10);
        setMaxYears(20);
      }
      setResetSlider((prev) => !prev);
    } else if (
      skillGraphDataIndex !== undefined &&
      skillGraphDatasetIndex !== undefined &&
      graphJobs !== undefined &&
      graphJobs.length > 0
    ) {
      var skillIds = graphJobs[skillGraphDataIndex].skills_dataset.map(
        function (str) {
          return parseInt(str);
        }
      );
      setJob_search(skillIds);
    }
  }, [skillGraphDataIndex, skillGraphDatasetIndex]);

  useEffect(() => {
    if (
      locationGraphDataIndex !== undefined &&
      locationGraphDatasetIndex !== undefined &&
      graphLocationsId !== undefined
    ) {
      var clickedLocationId = graphLocationsId[locationGraphDataIndex];
      var clickedRange = ranges[locationGraphDatasetIndex];
      var clickedRangeArray = clickedRange.split("-");
      if (clickedLocationId !== undefined) {
        setLocation([parseInt(clickedLocationId)]);
      }
      if (clickedRangeArray[1] !== undefined) {
        setMinYears(clickedRangeArray[0]);
        setMaxYears(clickedRangeArray[1]);
      } else {
        setMinYears(10);
        setMaxYears(20);
      }
      setResetSlider((prev) => !prev);
    }
  }, [locationGraphDataIndex, locationGraphDatasetIndex]);

  useEffect(() => {
    FindWork();
    setFilting("hidden");

    let skillName = '';
    let locationName = '';
    if (searchPayload.skills.length > 0) {
      skillName = getSkillChipsName(searchPayload.skills[0]);
    }
    if (searchPayload.locations.length > 0) {
      locationName = getLocationChipsName(searchPayload.locations[0]);
    }

    setSearchFilterState({ skill: [skillName], location: [locationName], keyword, searchType });
  }, [searchPayload]);

  useEffect(() => {
    if (isLoggedIn() && isCandidate()) {
      navigate("/");
    }

    ApiHelper.getLocations("USER_PROFILE_LOCATIONS").then((response) => {
      if (response.isSuccess) {
        storeLocalData(LOCATIONS, response.data);
        setDisplayLocations({
          count: response.data.length - 10,
          arr: response.data,
        });
      } else {
        consoleLogE("getLocations", response.message);
      }
    });

    ApiHelper.getSkills().then((response) => {
      if (response.isSuccess) {
        storeLocalData(SKILLS, response.data);

        var resdata = [];
        response.data.map((data, i) => {
          resdata.push(data);
        });

        setPreferredSkillName(resdata);
      } else {
        consoleLogE("getSkills", response.message);
      }
    });
  }, []);

  const candidateProfile = (candidate_id) => {
    setCurrentCandidateId(candidate_id);
    if (auth) {
      window.open(
        "/workprofile/view/" + candidate_id + "?q=" + keyword,
        "_blank"
      );
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    let checkbox = document.getElementsByClassName("checkboxUnchecked");

    if (checkbox) {
      for (let index = 0; index < checkbox.length; index++) {
        checkbox[index].checked = false;
      }
    }
  }, [location]);

  const [isSticky, setIsSticky] = useState(false);
  const [y, setY] = useState(document.scrollingElement.scrollHeight);

  const handleNavigation = useCallback(
    (e) => {
      if (y > window.scrollY) {
        filterScrollTopNav();
      } else if (y < window.scrollY) {
        filterScrollDownNav();
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const filterScrollDownNav = () => {
    const searchElement = document.getElementById("result_section");
    if (
      searchElement !== null &&
      searchElement.getBoundingClientRect().top < 0
    ) {
      setShowStickySearchBar(true);
      setIsStickySearch(false);
      addNavShadow();
    }
  };

  const filterScrollTopNav = () => {
    const searchElement = document.getElementById("result_section");
    if (
      searchElement !== null &&
      searchElement.getBoundingClientRect().top > 0
    ) {
      addNavShadow();
      setShowStickySearchBar(false);
      setIsStickySearch(false);
    }
  };

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const [showSearchCriteriaModal, setShowSearchCriteriaModal] = useState(false);
  const [searchCriteriaTitle, setSearchCriteriaTitle] = useState("");
  const [savedSearchCriterias, setSavedSearchCriterias] = useState([]);

  const saveSearchCriteria = () => {
    getSavedSearchCriterias();
    setShowSearchCriteriaModal(true);
  };

  const submitSaveSearchCriteria = () => {
    if (isNullOrEmpty(searchCriteriaTitle)) {
      toast.warn("Please provide a title");
    } else {
      const data = {
        title: searchCriteriaTitle,
        json: jsonPayload,
      };

      setLoading(true);
      ApiHelper.saveSearchCriteria(data).then((response) => {
        if (response.isSuccess) {
          setShowSearchCriteriaModal(false);
          toast.success(response.message);
          setSearchCriteriaTitle("");
        } else {
          toast.warn(response.message);
        }
        setLoading(false);
      });
    }
  };

  const getSavedSearchCriterias = () => {
    setModalLoading(true);
    ApiHelper.getSavedSearchCriteria().then((response) => {
      if (response.isSuccess) {
        setSavedSearchCriterias(response.data);
      } else {
        toast.warn(response.message);
      }
      setModalLoading(false);
    });
  };

  const deleteSavedSearchCriteria = (id) => {
    setLoading(true);
    ApiHelper.deleteSavedSearchCriteria(id).then((response) => {
      if (response.isSuccess) {
        toast.success(response.message);
        setSavedSearchCriterias(response.data);
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  };

  const applySavedSearchCriteria = (payload) => {
    setShowSearchCriteriaModal(false);
    setLoading(true);

    try {
      removeAllParams();

      let p = { ...defaultPayload };
      let urlParamsKeyArr = [];
      let urlParamsValueArr = [];

      if (payload.skills.length > 0) {
        p.skills = [...payload.skills];

        payload.skills.forEach((s) => {
          urlParamsKeyArr.push("skills");
          urlParamsValueArr.push(s);
        });
      }

      if (payload.locations.length > 0) {
        p.locations = [...payload.locations];

        payload.locations.forEach((l) => {
          urlParamsKeyArr.push("locations");
          urlParamsValueArr.push(l);
        });
      }

      if (payload.min_salary.length > 0 && payload.min_salary.length === payload.max_salary.length) {
        const salRangeArr = masterData?.salary_range?.filter((sr) => {
          const [min, max] = sr.value.split("-");
          const minValue = parseInt(
            commonServices.removeCommaFromAmount(min.trim())
          );
          const maxValue = parseInt(
            commonServices.removeCommaFromAmount(max.trim())
          );

          const valueFromMaster = `${minValue}-${maxValue}`;
          let valueFromPayload;

          for (let i = 0; i < payload.min_salary.length; i++) {
            valueFromPayload = `${payload.min_salary[i]}-${payload.max_salary[i]}`;
            return valueFromMaster === valueFromPayload;
          }
        });

        salRangeArr.forEach((sr) => {
          const [min, max] = sr.value.split("-");
          const minValue = parseInt(
            commonServices.removeCommaFromAmount(min.trim())
          );
          const maxValue = parseInt(
            commonServices.removeCommaFromAmount(max.trim())
          );

          urlParamsKeyArr.push("min_salary");
          urlParamsValueArr.push(minValue);
          p.min_salary.push(minValue);

          urlParamsKeyArr.push("max_salary");
          urlParamsValueArr.push(maxValue);
          p.max_salary.push(maxValue);

          urlParamsKeyArr.push("salary_id");
          urlParamsValueArr.push(sr.id);
          p.salary_id.push(sr.id);
        });
      }

      if (payload.min_experience !== 0) {
        const exp = payload.min_experience / 12;
        p.min_exp[0] = exp;
        urlParamsKeyArr.push("min_exp");
        urlParamsValueArr.push(exp);
      }

      if (payload.max_experience !== 240) {
        const exp = payload.max_experience / 12;
        p.max_exp[0] = exp;
        urlParamsKeyArr.push("max_exp");
        urlParamsValueArr.push(exp);
      }

      if (payload.joining_preferences.length > 0) {
        p.preferences = payload.joining_preferences;
        urlParamsKeyArr.push("preferences");
        payload.joining_preferences.forEach((p) => urlParamsValueArr.push(p));
      }

      if (payload.job_types.length > 0) {
        p.job_types = payload.job_types;
        urlParamsKeyArr.push("job_types");
        payload.job_types.forEach((jt) => urlParamsValueArr.push(jt));
      }

      if (payload.layoff !== "0") {
        p.layoff[0] = payload.layoff;
        urlParamsKeyArr.push("layoff");
        urlParamsValueArr.push("1");
      }

      if (payload.armed_forces !== "0") {
        p.armedForces[0] = payload.armed_forces;
        urlParamsKeyArr.push("armedForces");
        urlParamsValueArr.push("1");
      }

      if (payload.differently_abled !== "0") {
        p.differentlyAbled[0] = payload.differently_abled;
        urlParamsKeyArr.push("differentlyAbled");
        urlParamsValueArr.push("1");
      }

      if (payload.her_career_reboot !== "0") {
        p.herCareerReboot[0] = payload.her_career_reboot;
        urlParamsKeyArr.push("herCareerReboot");
        urlParamsValueArr.push("1");
      }

      if (payload.is_telecommute !== "0") {
        p.eeo[0] = payload.is_telecommute;
        urlParamsKeyArr.push("eeo");
        urlParamsValueArr.push("1");
      }

      if (!isNullOrEmpty(payload.updated_at_from) && !isNullOrEmpty(payload.updated_at_to)) {
        p.start_date[0] = new Date(payload.updated_at_from);
        p.end_date[0] = new Date(payload.updated_at_to);
        urlParamsKeyArr.push("start_date");
        urlParamsValueArr.push(payload.updated_at_from);
        urlParamsKeyArr.push("end_date");
        urlParamsValueArr.push(payload.updated_at_to);
      }

      let tempCriterias = [];
      const c = [...payload.similar_criteria];

      if (c.includes(SIMILAR_SEARCH_CRITERIA.EDUCATION)) {
        tempCriterias.push(SIMILAR_SEARCH_CRITERIA.EDUCATION);
        const value = `Education - ${payload.userNames[0]?.name
          } (${getIdWithPrefix(payload.userNames[0]?.id)})`;
        p.education[0] = value;
        urlParamsKeyArr.push("education");
        urlParamsValueArr.push(value);
      }

      if (c.includes(SIMILAR_SEARCH_CRITERIA.EMPLOYMENT)) {
        tempCriterias.push(SIMILAR_SEARCH_CRITERIA.EMPLOYMENT);
        const value = `Employment - ${payload.userNames[0]?.name
          } (${getIdWithPrefix(payload.userNames[0]?.id)})`;
        p.employment[0] = value;
        urlParamsKeyArr.push("employment");
        urlParamsValueArr.push(value);
      }

      if (c.includes(SIMILAR_SEARCH_CRITERIA.SKILLS)) {
        tempCriterias.push(SIMILAR_SEARCH_CRITERIA.SKILLS);
        urlParamsKeyArr.push("isSimilarSkills");
        urlParamsValueArr.push("1");
      }

      if (c.includes(SIMILAR_SEARCH_CRITERIA.LOCATION)) {
        tempCriterias.push(SIMILAR_SEARCH_CRITERIA.LOCATION);
        urlParamsKeyArr.push("isSimilarLocation");
        urlParamsValueArr.push("1");
      }

      if (!isNullOrEmpty(payload.similar_resume_user_id)) {
        setUserTTId(payload.similar_resume_user_id);
        urlParamsKeyArr.push("u");
        urlParamsValueArr.push(payload.similar_resume_user_id);
      }

      if (payload.order_by) {
        const o = payload.order_by;
        let text = ORDER_BY_TEXT.NEWEST;

        if (o === ORDER_BY.NEWEST) {
          text = ORDER_BY_TEXT.NEWEST;
        } else if (o === ORDER_BY.OLDEST) {
          text = ORDER_BY_TEXT.OLDEST;
        } else {
          text = ORDER_BY_TEXT.RELEVANT;
        }

        p.orderBy[0] = o;
        p.orderByText[0] = text;
      }

      setUserNames(payload.userNames);
      setSelectedCriterias(tempCriterias);
      addMultipleSearchParamsInHistory(urlParamsKeyArr, urlParamsValueArr);
      setSearchPayload(p);
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const renderTooltip = (type) => {
    let tooltipContent;

    switch (type) {
      case "evaluated":
        tooltipContent = "Evaluated";
        break;
      case "certified":
        tooltipContent = "Certified";
        break;
      case "trained":
        tooltipContent = "Trained";
        break;
      default:
        tooltipContent = "Default Tooltip";
    }

    return <Tooltip id="button-tooltip">{tooltipContent}</Tooltip>;
  };

  return (
    <>
      {loading ? (
        <div className="loader">
          <ClipLoader color={color} loading={true} css={override} size={150} />
        </div>
      ) : null}
      {isPaywallActive ? (
        <PayWall
          isPaywallActive={isPaywallActive}
          desc={"Best Candidates"}
          onCancel={() => {
            redirectBack();
          }}
        />
      ) : null}
      <div
        className={`overlay ${filting}`}
        onClick={() => {
          setFilting("hidden");
        }}
      ></div>

      <ModalLoginRequired
        showModal={showModal}
        setShowModal={setShowModal}
        candidate_id={currentCandidateId}
      />

      <ModalLocation
        showModal={showModalLocation}
        setShowModal={setShowModalLocation}
        locations={jobLocations}
        selectedLocations={searchPayload.locations}
        onChange={onLocationChange}
      />

      {/* Save Search Criteria Model */}
      <ModalSaveSearchCriteria
        showSearchCriteriaModal={showSearchCriteriaModal}
        setShowSearchCriteriaModal={setShowSearchCriteriaModal}
        searchCriteriaTitle={searchCriteriaTitle}
        setSearchCriteriaTitle={setSearchCriteriaTitle}
        submitSaveSearchCriteria={submitSaveSearchCriteria}
        savedSearchCriterias={savedSearchCriterias}
        deleteSavedSearchCriteria={deleteSavedSearchCriteria}
        applySavedSearchCriteria={applySavedSearchCriteria}
        modalLoading={modalLoading}
      />

      {/* View-similar Candidates Modal */}
      <ModalShowSimilarCandidates
        showSimilarCandidatesModal={showSimilarCandidatesModal}
        setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
        showSimilarSearch={showSimilarSearch}
        setSelectedCriterias={setSelectedCriterias}
        selectedCriterias={selectedCriterias}
        isSimilarCriteriaChecked={isSimilarCriteriaChecked}
      />

      <div className="candidate_page">
        <div className={"body_section tt-container " + (!isStickySearch ? 'padding-search' : '')}>
          <FilterBar searchedSkills={searchPayload.skills} setSearchedSkills={(type, data) => manageDataInPayload(type, data, 'a')} searchedLocations={searchPayload.locations} setSearchedLocations={(type, data) => manageDataInPayload(type, data, 'a')} FindWork={FindWork} searchType={searchBy} setSearchType={setSearchBy} keyword={keyword} setKeyword={setKeyword} searchBar={"normal"} module={"Candidates"} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} saveSearchCriteria={saveSearchCriteria} setPreferredSkillData={setPreferredSkillData} isStickySearch={isStickySearch} activePage={activePage} isKeywordReset={searchPayload.keywordReset[0]} setIsKeywordReset={setIsKeywordReset} />

          <div className="result_section" id="result_section">
            {featuredData?.data?.length > 0 ? (
              <div
                className={
                  isSticky ? "result_feed sticky-effect" : "result_feed"
                }
                id="result_feed"
              >
                <div className="result_header d-flex justify-content-between align-items-center">
                  <Text
                    type="h2"
                    text="Search result"
                    className="topTitle"
                  ></Text>
                  <div className="result_header_sort">
                    <Text text="Sort by:"></Text>
                    <div className="dropdown">
                      <a
                        className="btn btn-secondary dropdown-toggle"
                        href={undefined}
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {searchPayload.orderByText}
                      </a>

                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                        style={{ top: "101%", left: 0 }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSearchPayload({
                                ...searchPayload,
                                orderBy: ["MOST_RECENT"],
                                orderByText: ["Newest First"],
                              });
                              setOrderByUser(true);
                            }}
                            href={undefined}
                          >
                            Newest First
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSearchPayload({
                                ...searchPayload,
                                orderBy: ["POST_DATE"],
                                orderByText: ["Oldest First"],
                              });
                              setOrderByUser(true);
                            }}
                            href={undefined}
                          >
                            Oldest First
                          </a>
                        </li>
                        {isShowPercentage ? (
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({
                                  ...searchPayload,
                                  orderBy: ["MOST_RELEVANT"],
                                  orderByText: ["Most Relevant"],
                                });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                            >
                              Most Relevant
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </div>

                    <div className="filter_tab_header">
                      <div className="list-group" id="list-tab-1" role="tablist">
                        <a className="list-group-item list-group-item-action active" id="list_1" data-bs-toggle="list" href="#list_1_content" role="tab" aria-controls="list_1_content">
                          <CustomTooltip text="Grid View">
                            <div className='round'>
                              <img onError={commonServices.imgError} src="/Assets/svgs/list_view_icon.svg" alt="" className='ig' />
                            </div>
                          </CustomTooltip>
                        </a>
                        <a className="list-group-item list-group-item-action" id="list_2" data-bs-toggle="list" href="#list_2_content" role="tab" aria-controls="list_2_content">
                          <CustomTooltip text="Table View">
                            <div className='round'>
                              <img onError={commonServices.imgError} src="/Assets/svgs/grid_view_icon.svg" alt="" className='ig' />
                            </div>
                          </CustomTooltip>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-0" id="featured-3">
                  
                  {transformArrayFromObject(searchPayload).length > 0 && (
                    
                    <div className="row all-filters d-flex">
                      {isDataInPayload() ? 
                      <button className="clearBtn col-2 f_t" style={{color: "white",background: "#14bc9a"}} onClick={(e) => {
                        clearFilters(e);
                      }}>Clear All</button>: null}
                      {transformArrayFromObject(searchPayload).map(
                        (data, i) => {
                          <h1>{getChipsName(data)}</h1>
                          if (data["min_salary"] || data["max_salary"]) {
                            return null;
                          } else {
                            if (!isNullOrEmpty(getChipsName(data))) {
                              return (
                                <div key={i} className="col-2 f_t">
                                  <p>{getChipsName(data)}</p>
                                  <button
                                    className="del_jt"
                                    onClick={() => handleRemoveChips(data)}
                                  >
                                    <img
                                      src="../../Assets/svgs/x-grey.svg"
                                      alt="cross"
                                    />
                                  </button>
                                </div>
                              );
                            }
                          }
                        }
                      )}
                    </div>
                  )}

                  <div className="tab-content" id="nav_tabContent_1">
                    <div
                      className="tab-pane fade show active"
                      id="list_1_content"
                      role="tabpanel"
                      aria-labelledby="list_1"
                    >
                      <div className="px-0 pb-3" id="featured-3">
                        <div className="row row-cols-">
                          <div
                            style={
                              chart1loading
                                ? { height: "200px" }
                                : { height: "auto" }
                            }
                            className="col-md-6 col-12"
                            key={"ch"}
                          >
                            <Chart
                              chartData={chartData}
                              text={graphSkillsTitle}
                              setGraphDataIndex={setSkillGraphDataIndex}
                              setGraphDatasetIndex={setSkillGraphDatasetIndex}
                              chartRef={skillChartRef}
                              graphType={graphSkillsType}
                              loading={chart1loading}
                            />
                          </div>

                          <div
                            style={
                              chart2loading
                                ? { height: "200px" }
                                : { height: "auto" }
                            }
                            className="col-md-6 col-12"
                            key={"ch1"}
                          >
                            <Chart
                              chartData={chartLocationData}
                              text={graphLocationsTitle}
                              chartRef={locationChartRef}
                              setGraphDataIndex={setLocationGraphDataIndex}
                              setGraphDatasetIndex={
                                setLocationGraphDatasetIndex
                              }
                              graphType={graphLocationsType}
                              loading={chart2loading}
                            />
                          </div>
                        </div>
                        <div className="cards__container">
                          {featuredData.data.map((data, key) => (
                            <div>
                              <div key={key} className="view-similar job__card main-card">
                                {data?.is_pro_user && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      backgroundColor: "var(--green)",
                                      width: "80px",
                                      transform:
                                        "rotate(-45deg) translateX(-22%) translateY(-80%)",
                                      padding: "0.25rem",
                                      paddingTop: "0.5rem",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      left: 0,
                                      top: 0,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "white",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                      }}
                                      text="PRO"
                                    ></Text>
                                  </div>
                                )}

                                <div
                                  className="feature_content hand-hover "
                                  onClick={() => candidateProfile(data.id)}
                                >
                                  <div className="candidate-card-head">
                                    <div className="candidate-card-basic">
                                      <p
                                        className="paragraph_1 truncate-1"
                                        title={textCapitalise(data.full_name)}
                                        dangerouslySetInnerHTML={{
                                          __html: highlightText(
                                            keyword,
                                            textCapitalise(data.full_name)
                                          ),
                                        }}
                                      ></p>
                                      <Text
                                        text={`Exp: ${getCandidateExperienceText(
                                          data.total_experience
                                        )}`}
                                        className="paragraph_3"
                                      ></Text>
                                    </div>

                                    {isShowPercentage ? (
                                      <div className="circle-rating">
                                        <CircularProgressbar
                                          value={getPercentage(
                                            data.profile_percentage
                                          )}
                                          text={`${getPercentage(
                                            data.profile_percentage
                                          )}%`}
                                          styles={buildStyles({
                                            textColor: "#263238",
                                            pathColor: getBarColor(
                                              getPercentage(
                                                data.profile_percentage
                                              )
                                            ),
                                            trailColor: "#E3E3E3",
                                          })}
                                        />
                                      </div>
                                    ) : null}
                                  </div>

                                  <p
                                    className="paragraph_3 d-flex gap-1"
                                    style={{
                                      color: "#4B8BFF",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      style={{ height: "12px" }}
                                      src={mapPin}
                                      alt=""
                                    />{" "}
                                    <span
                                      className="truncate-1"
                                      title={"Current Location"}
                                    >
                                      {data?.location_name
                                        ? data?.location_name
                                        : "NA"}
                                    </span>
                                  </p>

                                  <p
                                    className="paragraph_3 truncate-2 trunc-desc mt-1"
                                    style={{
                                      minHeight: "40px",
                                      height: "40px",
                                      maxHeight: "40px",
                                      fontSize: "var(--p1)",
                                    }}
                                    title={
                                      data.skills !== null
                                        ? getSkillsString(data.skills)
                                        : "N/A"
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        keyword,
                                        textCapitalise(
                                          data.skills !== null
                                            ? getSkillsString(data.skills)
                                            : "N/A"
                                        )
                                      ),
                                    }}
                                  ></p>

                                  <div className="d-flex justify-content-between mt-2">
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      {data?.is_evaluated && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={renderTooltip("evaluated")}
                                        >
                                          <div
                                            style={{
                                              border: "solid 1px green",
                                              width: "20px",
                                              height: "20px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <span
                                              style={{ color: "var(--green)" }}
                                            >
                                              E
                                            </span>
                                          </div>
                                        </OverlayTrigger>
                                      )}

                                      {data?.is_certified && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={renderTooltip("certified")}
                                        >
                                          <div
                                            style={{
                                              border: "solid 1px green",
                                              width: "20px",
                                              height: "20px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <span style={{ color: "purple" }}>
                                              C
                                            </span>
                                          </div>
                                        </OverlayTrigger>
                                      )}

                                      {data?.is_trained && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={renderTooltip("trained")}
                                        >
                                          <div
                                            style={{
                                              border: "solid 1px green",
                                              width: "20px",
                                              height: "20px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <span style={{ color: "purple" }}>
                                              T
                                            </span>
                                          </div>
                                        </OverlayTrigger>
                                      )}
                                    </div>

                                    <div
                                      className="feature_content_tags d-flex"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        minHeight: "30px",
                                        maxHeight: "30px",
                                      }}
                                    >
                                      {data?.is_profile_viewed_by_user ? (
                                        <p>
                                          <IconResource
                                            type="doubletick"
                                            className="green-done"
                                          />
                                        </p>
                                      ) : data?.is_profile_viewed_by_company_user ? (
                                        <p>
                                          <IconResource
                                            type="doubletick"
                                            className="done"
                                          />
                                        </p>
                                      ) : null}

                                      <p
                                        style={{ color: "#9398A1" }}
                                        className="paragraph_4 truncate-1"
                                        title={commonServices.dateFormat(
                                          data.updated_at
                                        )}
                                      >
                                        {commonServices.dateFormat(
                                          data.updated_at
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  onClick={() => {
                                    setShowSimilarCandidatesModal(data);
                                    setUserTTId(data.id);
                                  }}
                                  className="circular-popup"
                                >
                                  <i
                                    className="fa fa-clone"
                                    aria-hidden="true"
                                  ></i>
                                  <p>View Similar</p>
                                </div>

                                <div
                                  className="hover-popup"
                                  onClick={() => addCandidateToSaveLater(data.id, setLoading, false)}
                                >
                                  <Button
                                    buttonType="secondary"
                                    buttonIcon={
                                      <i
                                        className="fa fa-heart-o"
                                        aria-hidden="true"
                                      ></i>
                                    }
                                    text="Save Candidate"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="list_2_content"
                      role="tabpanel"
                      aria-labelledby="list_2"
                    >
                      <div className="row row-cols-1 py-3">
                        <div className="col-md-6 col-12" key={"ch2"}>
                          <Chart
                            chartData={chartData}
                            text={graphSkillsTitle}
                            setGraphDataIndex={setSkillGraphDataIndex}
                            setGraphDatasetIndex={setSkillGraphDatasetIndex}
                            chartRef={skillListViewChartRef}
                            graphType={graphSkillsType}
                          />
                        </div>

                        <div className="col-md-6 col-12" key={"ch3"}>
                          <Chart
                            chartData={chartLocationData}
                            text={graphLocationsTitle}
                            chartRef={locationListViewChartRef}
                            setGraphDataIndex={setLocationGraphDataIndex}
                            setGraphDatasetIndex={setLocationGraphDatasetIndex}
                            graphType={graphLocationsType}
                          />
                        </div>
                      </div>

                      <div className="common_items">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="25%" scope="col">
                                Name
                              </th>
                              <th scope="col">Skills</th>
                              <th scope="col">Experience</th>
                              {isShowPercentage ? (
                                <th scope="col">Matching Percentage</th>
                              ) : null}
                              <th width="15%" scope="col">
                                Last updated
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {featuredData.data.map((data, key) => {
                              return (
                                <tr
                                  key={key}
                                  className="hover"
                                  onClick={() => candidateProfile(data.id)}
                                >
                                  <td width="25%" scope="col" className="bold">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          keyword,
                                          textCapitalise(data.full_name)
                                        ),
                                      }}
                                    ></p>
                                  </td>
                                  <td scope="col">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          keyword,
                                          textCapitalise(
                                            data.skills !== null
                                              ? getSkillsString(data.skills)
                                              : "N/A"
                                          )
                                        ),
                                      }}
                                    ></p>
                                  </td>
                                  <td scope="col">
                                    {getCandidateExperienceText(
                                      data.total_experience
                                    )}
                                  </td>
                                  {isShowPercentage ? (
                                    <td scope="col">
                                      {getPercentage(data.profile_percentage)}%
                                    </td>
                                  ) : null}
                                  <td width="15%" scope="col">
                                    {commonServices.dateFormat(data.updated_at)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div>
                    {skillUsersCount > 0 &&
                      skillUsersCount - featuredData?.total > 0 ? (
                      <p className="page-size-info">
                        {skillUsersCount - featuredData?.total} more candidates
                        are available on
                        {preferredSkillName && preferredSkillName.length > 0 ? (
                          <>
                            {" "}
                            {job_search.map((jSearch, i) => {
                              return removeDuplicates(preferredSkillName).map(
                                (skill, k) => {
                                  if (skill.id === jSearch)
                                    return i !== 0
                                      ? ", " + skill.name + " "
                                      : skill.name + " ";
                                }
                              );
                            })}
                          </>
                        ) : null}
                        without matching other criteria
                      </p>
                    ) : null}

                    <div className="my-3">
                      <CustomPagination
                        activePage={activePage}
                        onPageChange={handlePaginationChange}
                        totalData={featuredData?.total}
                        pageLength={featuredData?.per_page}
                        lastPage={featuredData?.last_page}
                        currentPage={setActivePage}
                        isPaywallActiveCancel={isPaywallActiveCancel}
                        dataFrom={featuredData.from}
                        dataTo={featuredData.to}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="result_feed">
                <div className="result_header d-flex justify-content-between align-items-center">
                  <Text
                    type="h2"
                    text="Search result"
                    className="topTitle"
                  ></Text>
                  <div className="result_header_sort">
                    <Text text="Sort by:"></Text>
                    <div className="dropdown">
                      <a
                        className="btn btn-secondary dropdown-toggle"
                        href={undefined}
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {searchPayload.orderByText}
                      </a>

                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                        style={{ top: "101%", left: 0 }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.NEWEST], orderByText: [ORDER_BY_TEXT.NEWEST] });
                              setOrderByUser(true);
                            }}
                            href={undefined}
                          >
                            Newest First
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.OLDEST], orderByText: [ORDER_BY_TEXT.OLDEST], });
                              setOrderByUser(true);
                            }}
                            href={undefined}
                          >
                            Oldest First
                          </a>
                        </li>
                        {isShowPercentage ? (
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.RELEVANT], orderByText: [ORDER_BY_TEXT.RELEVANT] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                            >
                              Most Relevant
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </div>

                    <div className="filter_tab_header">
                      <div
                        className="list-group"
                        id="list-tab-1"
                        role="tablist"
                      >
                        <a
                          className="list-group-item list-group-item-action active"
                          id="list_1"
                          data-bs-toggle="list"
                          href="#list_1_content"
                          role="tab"
                          aria-controls="list_1_content"
                        >
                          <div className="round">
                            <img
                              onError={commonServices.imgError}
                              src="/Assets/svgs/list_view_icon.svg"
                              alt=""
                              className="ig"
                            />
                          </div>
                        </a>
                        <a
                          className="list-group-item list-group-item-action"
                          id="list_2"
                          data-bs-toggle="list"
                          href="#list_2_content"
                          role="tab"
                          aria-controls="list_2_content"
                        >
                          <div className="round">
                            <img
                              onError={commonServices.imgError}
                              src="/Assets/svgs/grid_view_icon.svg"
                              alt=""
                              className="ig"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-0" id="featured-3">
                  <div className="" id="featured_3">
                    <div className="row all-filters d-flex ">
                      {Object.values(searchPayload).length > 0 &&
                        transformArrayFromObject(searchPayload).map(
                          (data, i) => {
                            if (data["min_salary"] || data["max_salary"]) {
                              return null;
                            } else {
                              if (!isNullOrEmpty(getChipsName(data))) {
                                return (
                                  <div key={i} className="col-2 f_t">
                                    <p>{getChipsName(data)}</p>
                                    <button
                                      className="del_jt"
                                      onClick={() => handleRemoveChips(data)}
                                    >
                                      <img
                                        src="../../Assets/svgs/x-grey.svg"
                                        alt="cross"
                                      />
                                    </button>
                                  </div>
                                );
                              }
                            }
                          }
                        )}
                    </div>
                  </div>
                </div>

                <div className="blank_content d-flex">
                  {noResultErr && (
                    <>
                      <img
                        onError={commonServices.imgError}
                        src={emptyResult}
                        alt=""
                      />
                      <h2>Sorry we couldn't find any matches</h2>
                      <p>
                        No records found. Please retry with other search
                        parameters
                      </p>
                      <div
                        className="form_buttons"
                        style={{ marginTop: "2em" }}
                      >
                        <Button
                          buttonType="danger"
                          onClick={(e) => {
                            clearFilters(e);
                          }}
                          text="Clear"
                        />
                        <Button
                          buttonType="secondary"
                          onClick={() => {
                            window.history.back();
                          }}
                          text="Back"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Search Filter Drawer -- Starts */}
        <button
          className={"drawer__toggle-btn"}
          onClick={() => {
            setDrawerIsOpen(!drawerIsOpen);
          }}
        >
          {/* Drawer Toggle Button */}
          <Text
            style={{ fontWeight: "700", wordSpacing: "8px" }}
            text="FILTER YOUR RESULTS"
          ></Text>
        </button>

        <div
          className={`drawer__wrapper ${drawerIsOpen ? "show" : ""}`}
          style={{ right: drawerIsOpen ? 0 : "-100%" }}
        >
          <div
            className="drawer__overlay"
            style={{ inset: drawerIsOpen ? 0 : "auto" }}
            onClick={() => {
              setDrawerIsOpen(!drawerIsOpen);
            }}
          ></div>

          <div
            className="drawer "
            style={{
              width: drawerIsOpen ? "400px" : "0px",
            }}
          >
            <div className="drawer__header">
              <h2>Filter Search</h2>
              <div className="drawer__header-clear">
                <div className="clearBtn" onClick={(e) => clearFilters(e)}>
                  Clear All
                </div>

                <RxCross2
                  style={{
                    cursor: "pointer",
                  }}
                  color="var(--green)"
                  size={20}
                  className="closeBtn"
                  onClick={() => {
                    setDrawerIsOpen(!drawerIsOpen);
                  }}
                />
              </div>
            </div>

            <div className="drawer__body">
              <form className="result_filter" id="result_filter">
                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper"><p className='titleCls'>Preferred Job Location</p></div>
                  <div>
                    <div className="filter-search">
                      <input
                        type="text"
                        placeholder="City"
                        id="search_location_filter"
                        onChange={(e) =>
                          handleDisplayLocationChange(e.target.value)
                        }
                      />
                    </div>

                    {displayLocations.arr?.length > 0 ? (
                      displayLocations.arr
                        .sort((a, b) => {
                          return (
                            searchPayload.locations.includes(b.id) -
                            searchPayload.locations.includes(a.id)
                          );
                        })
                        .slice(0, 10)
                        .map((loc, i) => (
                          <div key={i} className="checkbox_div_c">
                            <input
                              type="checkbox"
                              name=""
                              id={`loc_checked_c_d${i}`}
                              checked={searchPayload.locations.includes(loc.id)}
                              onChange={(e) => {
                                manageDataInPayload(
                                  "locations",
                                  loc.id,
                                  e.target.checked ? "a" : "r"
                                );
                                handleDisplayLocationChange("");
                                document.getElementById(
                                  "search_location_filter"
                                ).value = "";
                              }}
                            />
                            <label
                              className="form-label"
                              htmlFor={`loc_checked_c_d${i}`}
                            >
                              {loc.name}, {loc.description}
                            </label>
                          </div>
                        ))
                    ) : (
                      <em>Try Searching different Location!</em>
                    )}

                    {displayLocations.count > 10 &&
                      <div className="more" >
                        <p className="hand-hover" onClick={() => { setShowModalLocation(true); setDrawerIsOpen(false); }}>+{displayLocations.count - 10} More
                        </p>
                      </div>
                    }
                  </div>
                </div>

                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p>Expected Salary</p>
                  </div>
                  <div>
                    {masterData?.salary_range
                      .sort(
                        (a, b) =>
                          searchPayload.salary_id.includes(b.id) -
                          searchPayload.salary_id.includes(a.id)
                      )
                      .map((s, i) => {
                        return (
                          <div key={i} className="checkbox_div">
                            <input
                              type="checkbox"
                              name=""
                              id={`salary_range_checked_${i}`}
                              checked={searchPayload.salary_id.includes(s.id)}
                              onChange={(e) =>
                                manageDataInPayload(
                                  "salary_id",
                                  s.id,
                                  e.target.checked ? "a" : "r"
                                )
                              }
                            />
                            <label
                              className="form-label"
                              htmlFor={`salary_range_checked_${i}`}
                            >
                              {CURRENCY} {s.name}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="filter-category leftJobTypeTab d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p>Preferred Job Type</p>
                  </div>
                  <div>
                    {masterData?.job_types
                      .sort(
                        (a, b) =>
                          searchPayload.job_types.includes(b.id) -
                          searchPayload.job_types.includes(a.id)
                      )
                      .map((jt, i) => {
                        return (
                          <div key={i} className="checkbox_div">
                            <input
                              type="checkbox"
                              name=""
                              id={`job_type_checked_${i}`}
                              checked={searchPayload.job_types.includes(jt.id)}
                              onChange={(e) =>
                                manageDataInPayload(
                                  "job_types",
                                  jt.id,
                                  e.target.checked ? "a" : "r"
                                )
                              }
                            />
                            <label
                              className="form-label"
                              htmlFor={`job_type_checked_${i}`}
                            >
                              {jt.name}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="filter-category leftJobTypeTab d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p>Joining Preferences</p>
                  </div>
                  <div>
                    {masterData?.joining_preferences
                      .sort(
                        (a, b) =>
                          searchPayload.preferences.includes(b.id) -
                          searchPayload.preferences.includes(a.id)
                      )
                      .map((preference, i) => {
                        return (
                          <div key={i} className="checkbox_div">
                            <input
                              type="checkbox"
                              name=""
                              id={`joining_preferences_checked_${i}`}
                              checked={searchPayload.preferences.includes(
                                preference.id
                              )}
                              onChange={(e) =>
                                manageDataInPayload(
                                  "preferences",
                                  preference.id,
                                  e.target.checked ? "a" : "r"
                                )
                              }
                            />
                            <label
                              className="form-label"
                              htmlFor={`joining_preferences_checked_${i}`}
                            >
                              {preference.name}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p>Total Experience</p>
                  </div>
                  <div className="flex-column">
                    <MultiRangeSlider
                      min={0}
                      max={20}
                      defaultValue={{
                        min: searchPayload.min_exp[0],
                        max: searchPayload.max_exp[0],
                      }}
                      resetSlider={resetSlider}
                      onChange={({ min, max }) => {
                        if (searchPayload.min_exp[0] !== min) {
                          manageDataInPayload('min_exp', min, '');
                        } else if (searchPayload.max_exp[0] !== max) {
                          manageDataInPayload('max_exp', max, '');
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p className="s_heading">
                      Impacted by layoff
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={searchPayload.layoff[0] === "1"}
                          onChange={(e) =>
                            manageDataInPayload(
                              "layoff",
                              e.target.checked ? "1" : "0"
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </p>
                  </div>
                </div>

                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p className="s_heading">
                      Her Career Reboot
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={searchPayload.herCareerReboot[0] === "1"}
                          onChange={(e) =>
                            manageDataInPayload(
                              "herCareerReboot",
                              e.target.checked ? "1" : "0"
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </p>
                  </div>
                </div>

                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p className="s_heading">
                      Differently Abled
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={searchPayload.differentlyAbled[0] === "1"}
                          onChange={(e) =>
                            manageDataInPayload(
                              "differentlyAbled",
                              e.target.checked ? "1" : "0"
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </p>
                  </div>
                </div>

                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p className="s_heading">
                      Armed Forces
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={searchPayload.armedForces[0] === "1"}
                          onChange={(e) =>
                            manageDataInPayload(
                              "armedForces",
                              e.target.checked ? "1" : "0"
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </p>
                  </div>
                </div>

                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p className="s_heading">
                      Travel Readiness
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={searchPayload.eeo[0] === "1"}
                          onChange={(e) =>
                            manageDataInPayload(
                              "eeo",
                              e.target.checked ? "1" : "0"
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </p>
                  </div>
                </div>

                <div className="filter-category d-flex flex-column">
                  <div className="filter_title-wrapper">
                    <p>Profile Updated Between</p>
                  </div>

                  <DateRangePicker startDate={searchPayload.start_date[0]} setStartDate={(type, date) => manageDataInPayload(type, date, 'a')} endDate={searchPayload.end_date[0]} setEndDate={(type, date) => manageDataInPayload(type, date, 'a')} datePickerStartRef={datePickerStartRef} datePickerEndRef={datePickerEndRef} isDateFilter={isDateFilter} setIsDateFilter={setIsDateFilter} showDateShortcuts={true} onChange={(start, end) => {
                    manageDataInPayload('dates', [start, end], '');
                  }} usedInSearchComp={true} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateSearch;
